import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import neihu5 from '../assets/images/neihu5.jpg'
import neihu8 from '../assets/images/neihu8.jpg'
import neihu10 from '../assets/images/neihu10.jpg'
import banCiao5 from '../assets/images/IMG_7885.jpg'
import banCiao6 from '../assets/images/IMG_7889.jpg'
import banCiao7 from '../assets/images/IMG_7887.jpg'
import yonghe1 from '../assets/images/trig_010.webp'
import yonghe2 from '../assets/images/trig_003.webp'
import yonghe3 from '../assets/images/trig_005.webp'
import googleMap from '../assets/images/google-maps.png'

const Coach = props => (
  <Layout>
    <Helmet>
      <title>三健客 3musclers - 教練課</title>
      <meta name="description" content="三健客教練課" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>選擇教練課地點</h1>
          </header>
          <a href="#yonghe" className="button" style={{ margin: '5px' }}>
            永和店教練課
          </a>
          <a href="#banCiao" className="button" style={{ margin: '5px' }}>
            板橋店教練課
          </a>
          <a href="#neihu" className="button" style={{ margin: '5px' }}>
            內湖店教練課
          </a>
          <header className="major" id="yonghe" style={{ marginTop: '20px' }}>
            <h3>永和店教練課</h3>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image fit">
                  <img src={yonghe2} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={yonghe3} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={yonghe1} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h4>📌永和店教練課程收費如下：</h4>
          <ul>
            1. 一對一 私人教練課：( 上課時間為60分鐘 )<li>8堂：12000元。</li>
          </ul>
          <ul>
            2. 一對二 私人教練課：
            <li>8堂：13600元。</li>
          </ul>
          <h4>📌一對一私人教練課體驗。</h4>
          <ul>
            (每位限一次，時間為60分鐘)
            <li>私人教練課體驗價：800元/人。</li>
          </ul>
          <p>
            歡迎私訊、電話或來店諮詢報名一對一或團體課程！
            <br /> 電話：02-8942-2056
            <br />
            地址：
            <a
              href="https://goo.gl/maps/5qeCM9xEuGAwwQDK9"
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              新北市永和區民權路80號2樓
              <img src={googleMap} style={{ width: '24px' }} />
            </a>
          </p>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/3muscler/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/3musclers/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          <header className="major" id="banCiao" style={{ marginTop: '20px' }}>
            <h3>板橋店教練課</h3>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image fit">
                  <img src={banCiao5} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={banCiao6} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={banCiao7} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h4>📌板橋店教練課程收費如下：</h4>
          <ul>
            1. 一對一 私人教練課：( 上課時間為60分鐘 )<li>8堂：12000元。</li>
          </ul>
          <ul>
            2. 一對二 私人教練課：
            <li>8堂：13600元。</li>
          </ul>
          <h4>📌一對一私人教練課體驗。</h4>
          <ul>
            (每位限一次，時間為60分鐘)
            <li>私人教練課體驗價：800元/人。</li>
          </ul>
          <p>
            歡迎私訊、電話或來店諮詢報名一對一或團體課程！
            <br /> 電話：02-8258-8025
            <br />
            地址：
            <a
              href="https://goo.gl/maps/2NJbg4ox6gW3BQR78"
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              新北市板橋區莒光路211號b1
              <img src={googleMap} style={{ width: '24px' }} />
            </a>
          </p>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/%E4%B8%89%E5%81%A5%E5%AE%A2-3musclers-%E6%9D%BF%E6%A9%8B%E5%BA%97-125554492170293/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/3musclers/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          <header className="major" id="neihu" style={{ marginTop: '20px' }}>
            <h3>內湖店教練課</h3>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image fit">
                  <img src={neihu10} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={neihu5} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={neihu8} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h4>📌內湖店教練課程收費如下：</h4>

          <ul>
            1. 一對一 私人教練課：(上課時間為60分鐘)
            <li>單堂：1600元。 </li>
            <li>10堂：14000元。</li>
          </ul>
          <ul>
            2. 一對二 私人教練課：
            <li>單堂：2500元。 </li>
            <li>10堂：20000元。</li>
          </ul>
          <h4>📌近期推出的優惠方案和活動：</h4>
          <ul>
            1. 運動1+1方案：(有限定時段、不提供刷卡服務)
            <li>
              報名一對一私人教練課，即可免費攜伴一名！兩位需相同時段來上課，上課時間為60分鐘！{' '}
            </li>
            <li>單堂：1600元。 </li>
            <li>10堂：14000元。</li>
            <li>
              上課時段限定：
              <br />
              平日10:00~17:00 & 假日10:00~18:00
            </li>
          </ul>
          <ul>
            2.
            學生專案：(有限定時段、限定學生使用、不提供刷卡服務)
            <li>一對一 學生專案 5堂：5000元。</li>
            <li>一對二 學生專案 5堂：6000元。</li>
            <li>
              上課時段限定：
              <br />
              平日10:00~17:00。{' '}
            </li>
          </ul>
          <h4>📌私人教練課、學生專案和團體課程，皆提供體驗課。</h4>
          <ul>
            {' '}
            (每位限一次，時間為60分鐘)
            <li>私人教練課體驗價：500元/人。</li>
            <li>學生專案課體驗價：500元/人。</li>
            <li>團體課程體驗價：250元/人。</li>
          </ul>
          <p>
            另有提供場地租借200元起
            歡迎私訊、電話或來店諮詢報名一對一或團體課程！
            <br /> 電話：02-8751-1915
            <br />
            地址：
            <a
              href="https://goo.gl/maps/o2jCnwMGA9ZVmRqJ8"
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              台北市內湖區港華街18號
              <img src={googleMap} style={{ width: '24px' }} />
            </a>
          </p>
          <p></p>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/3musclers/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/explore/locations/172586599964912/3musclers-/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
)

export default Coach
